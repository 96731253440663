require('datatables.net-bs4');
require('datatables.net-select-bs4');
require("datatables.net-bs4/css/dataTables.bootstrap4.min.css");

let table_searched = false; 

$(document).ready(function(){

    opportunity = $("#notes-button").data('opportunity-id');
    user_id = null; 

    $('[data-opportunity-id]').click(function() {
        if( $(this).data('opportunity-id') ) {
            opportunity = $(this).data('opportunity-id');
        }
        if( $(this).data('user-id') ) {
            user_id = $(this).data('user-id');
        }
    });

    $('[data-filter-apply]').on('click', function() {
        const $this_filter_group = $(this).parents('.filter__group');

        if( $this_filter_group.hasClass('filter__group--modal') ) {
            $('#filter-modal-type').modal('hide');
        } else {
            $this_filter_group.toggleClass('d-none');
        }

        let group_checked_arr = []; 

        $('.btn__filter').removeClass('btn__filter--active')
    
        $('input[type="checkbox"]').each(function() {
            const type_index = user_logged_in ? 5 : 3; 

            $checkbox_filter_group = $(this).parents('.filter__group');
    
            let $filter_button =  $checkbox_filter_group.siblings('.btn__filter');
    
            if( $checkbox_filter_group.hasClass('filter__group--modal') ) {
                $filter_button = $('#opportunities-table .filter__header th:eq(' + type_index + ') .btn__filter');
            } 
     
            if( $(this).is(':checked') && $.inArray($checkbox_filter_group, group_checked_arr) == -1 ) {
                
                group_checked_arr.push($checkbox_filter_group);
                $filter_button.addClass('btn__filter--active');

            }
        });

    });

    $('[data-filter-trigger]').on('click', function() { 
        $filter_group = $(this).siblings('.filter__group');
        $filter_group.toggleClass('d-none');
    });

    $('[data-filter-clear]').on('click', function() {
        $filter_group = $(this).parents('.filter__group');
        $filter_group.find('input[type="checkbox"]').prop( "checked", false );
    });

    $('[data-filter-category]').on('change', function() {
        $filter_section = $(this).parents('.filter__section');

        if($(this).is(':checked')) {
           
            $filter_section.find('input[type="checkbox"]').not($(this)).prop( "checked", true );

        } else {
            
            $filter_section.find('input[type="checkbox"]').not($(this)).prop( "checked", false );

        }

    })



    // add notes

    $(".back-note").click(function() {
        $('.add-note-area').hide();
        $('.edit-note-area').hide();
        $('.notes-area').show();
        $('#add-note').addClass('d-inline-block').removeClass('d-none');
    });

    $("#add-note").click(function(){
    // clear the notes area

    $("#add-note").removeClass('d-inline-block').addClass('d-none');
        $('.notes-area').hide();
        $('.add-note-area').show();
    });

    $("#submit-note").click(function(){
        let data = {
            opportunity_id: opportunity,
            note: $("#note-to-add").val() 
        };
        if (user_id != null) {
            data.user_id = user_id;
        }

        $.ajax({
            url: '/opportunities/new-note',
            data: data,
            success: function(note){
                clearNotes();
            }
        })
    });

// edit a note
$(document).on('click', '.edit-note', function(){
    $("#edit-note").attr('data-note-id', note);

    $("#add-note").removeClass('d-inline-block').addClass('d-none');
    $('.notes-area').hide();
    $('.edit-note-area').show();

    let data = {
        note: $(this).data('note-id')
    };
    if (user_id != null) {
        data.user_id = user_id;
    }

    // obtain the note
    $.ajax({
        url: '/opportunities/note',
        data: data,
        success: function(note) {
            // display the note
            $('.notes-area').hide();
            $('.add-note-area').hide();
            $('.edit-note-area').show();
            $("#note-to-edit").val(note.note.note)
        }
    })
});

// delete a note
$(document).on('click', '.delete-note', function(){
    let data = {
        note: $(this).data('note-id')
    };
    if (user_id != null) {
        data.user_id = user_id;
    }
    $.ajax({
        url: '/opportunities/delete-note',
        data: data,
        success: function(note) {
            clearNotes();
        }
    })
});

// submit editing a note
$("#edit-note").click(function(){

    let data = {
        note_id: $(this).data('note-id'),
        note: $("#note-to-edit").val() 
    };
    if (user_id != null) {
        data.user_id = user_id;
    }
    $.ajax({
        url: '/opportunities/edit-note',
        data: data,
        success: function(note) {
            clearNotes();
        }
    })
});

// load notes
$('#notes-modal').on('shown.bs.modal', function (e) {
    // ajax function to get notes
    clearNotes();
    })
});
 
function dismissNotice(e) {
  $('.notice--cookie').hide();
  localStorage.setItem('cookie_notice', true);
}

function noticeCheck() {
  if (localStorage.getItem("cookie_notice") === null) {
    $('.notice--cookie').show();
  }
}

function getRegex(string) {
    return '^' + $.fn.dataTable.util.escapeRegex(string) + '$';
};

function setTableHeaderFilters(col_number, $filter_group) {

    console.log($filter_group);
 
    //get values for filter
    const $selected_checkboxes = $filter_group.find('input[type=checkbox]:checked');
    let selected_data_arr = [];
    
    $selected_checkboxes.each(function() {
        selected_data_arr.push($(this).val());
    })

    selected_data_arr = selected_data_arr.filter(function(item) { return item !== '' });
 
    //allow retained/participations
    const retained_participations_index_arr = user_logged_in ? [6,7,8] : [4,5,6]; 
    
    if ($.inArray(col_number, retained_participations_index_arr) != -1) { 
        //search for 'all' as well

        console.log(selected_data_arr)

        if (selected_data_arr.length > 0 ) {
            const $retained_participations_group = $('#opportunities-table .filter__header th:eq(' + col_number + ') .filter__group');

            let all_options_arr = [];
        
            $retained_participations_group.find('input[type="checkbox"]').each(function() {

                all_options_arr.push($(this).val());

            });

            all_options_arr.sort(); 
            
            const all_options = all_options_arr.join(', ');
        
            selected_data_arr.push(all_options);
            console.log(all_options)
        }
    }

    const all_searched_items_arr = $('input[type="checkbox"]:checked').map(function(){
        if ($(this).val() != '') {
            return $(this).val();
        }
    }).get();

    if (all_searched_items_arr.length > 0) {
        $('[data-filter-reset]').removeClass('d-none');
    } else {
        $('[data-filter-reset]').addClass('d-none');
        $('.btn__filter').removeClass('btn__filter--active')
    }

    //remove 'both' and empty strings from search
    selected_data_arr = selected_data_arr.filter(item => item !== 'Both' && item != '');

    return selected_data_arr;
}

var sort_order_key = 2;

if(user_logged_in) {
    sort_order_key = 4;
}
  
var savedSelected;

$(document).ready(function() {

    var table = $('#opportunities-table').DataTable({
        lengthChange: false,
        orderCellsTop: true,
        stateSave: true,
        stateDuration: -1,
        stateSaveParams: function(settings, data) {
            data.selected = this.api().rows({selected:true})[0];
        },
        stateLoadParams: function(settings, data) {
            savedSelected = data.selected;
        },
        info: false,
        pageLength: 200,
        searching: true,
        aaSorting: [ [sort_order_key,'asc'], [sort_order_key + 1,'asc'] ],
        initComplete: function () {
            this.api().rows(savedSelected).select();
            this.api().state.save();

            const table = this.api(); 

            this.api().columns().every( function () {
                var column = this;
                var array = [];

                this.ordererable = false;
                               
                column.data({ search:'applied' }).each(function(value, index) {
                    array.push(value);
                });

                var exclude_cols = user_logged_in ? [0,9] : [0,7];

                if ($.inArray(column.index(), exclude_cols) == -1) {
                    this.ordererable = true; 
                    //get values from filter menus
                    $filter_group = $('#opportunities-table .filter__header th:eq(' + column.index() + ') .filter__group');

                    const type_index = user_logged_in ? 5 : 3; 

                    //if "types", get values from modal 
                    if (column.index() == type_index) {  
                        $filter_group = $('[data-filter-group-types]');
                    }

                    $filter_group.find('[data-filter-apply]').on( 'click', function () {

                        $group = $(this).parents('.filter__group');

                        const selected_data_arr = setTableHeaderFilters(column.index(), $group);

                        //get values for datatables                            
                        var data = $.map(selected_data_arr, function(value) {
                            return getRegex(value);
                        })
                    
                        if (data.length === 0 ) {
                            data = [""];
                        } 

                        var val = data.join('|');

                        //write table
                        column
                            .search( val ? val : '', true, false )
                            .draw();
                        
                    }); 
                }
            });
        }

    });


    table.on('select deselect', function() {
        table.state.save();
    });

    var state = table.state.loaded();

    if ( state ) {

        if( $('input[type=search]').val().length > 0 ) {
            $('.form-control--datatable').val($('input[type=search]').val())
            $('[data-clear-input]').css({display: 'flex'});
        }

        let table_searched = false; 
        let full_search_arr = [];

        table.columns().eq( 0 ).each( function ( column_index ) {
            const colSearch = state.columns[column_index].search;
            let column = this;

            $('.select2-results__option').removeClass('')

            $filter_group = $('#opportunities-table .filter__header th:eq(' + column_index + ') .filter__group');

            const type_index = user_logged_in ? 5 : 3; 

            //if "types", get values from modal 
            if (column_index == type_index) {  
                $filter_group = $('[data-filter-group-types]');
            }

            if ( colSearch.search ) {
                let search = colSearch.search;
                let selected_data_arr = search.split('$|^');
                table_searched = true; 
                selected_data_arr = $.map(selected_data_arr, function(value) {
                    return value.replace('^','').replace('$','');
                });
                full_search_arr = full_search_arr.concat(selected_data_arr);

                $('[data-filter-reset]').removeClass('d-none'); 
                $('[data-filter-row]').removeClass('d-none');

                var data = $.map(selected_data_arr, function(value) {
                    return getRegex(value);
                })
            
                if (data.length === 0 ) {
                    data = [""];
                } 

                var val = data.join('|');

                //write table
                column
                    .search( val ? val : '', true, false )
                    .draw();
                
            }
        });

        if (table_searched) {
            $('[data-filter-reset]').removeClass('d-none');

            let group_checked_arr = []; 
            $('input[type="checkbox"]').each(function() {
                const type_index = user_logged_in ? 5 : 3; 
                const $checkbox_filter_group = $(this).parents('.filter__group');
                let $filter_button =  $checkbox_filter_group.siblings('.btn__filter');

                if( $checkbox_filter_group.hasClass('filter__group--modal') ) {
                    $filter_button = $('#opportunities-table .filter__header th:eq(' + type_index + ') .btn__filter');
                } 
                if ($.inArray($(this).val(), full_search_arr) != -1) {
                    $(this).prop( "checked", true );

                    if($.inArray($checkbox_filter_group, group_checked_arr) == -1 ) { 
                        group_checked_arr.push($checkbox_filter_group);
                        $filter_button.addClass('btn__filter--active');
                    }
                }
            });

        } else {
            $('[data-filter-reset]').addClass('d-none');
        }
        
        table.search('').draw()

    }

    $('[data-search-datatable]').on('keyup', function(e) {
        var $this = $(e.target);
        var val = $this.val();

        $('#opportunities-table_filter input[type="search"]').val(val);
        $('#opportunities-table_filter input[type="search"]').trigger("keyup");

        if( val.length > 0 ) {
            $('[data-clear-input]').css({display: 'flex'});
        } else {
            $('[data-clear-input]').hide();
        }
    });

    $('[data-clear-input]').on('click', function(e) {
        $('[data-search-datatable]').val('').trigger('keyup');
    });

    $('[data-filter-reset]').click(function() {
        $("#opportunities-table thead tr:eq(1) th select").val('').removeClass('select--inactive');
        $('[data-filter-reset]').addClass('d-none');
        $("#opportunities-table thead tr:eq(1) th select").trigger('change');
        table.search('').columns().search('').draw();
        $('input[type="checkbox"]').prop( "checked", false );
        $('.btn__filter').removeClass('btn__filter--active')

        table_searched = false;

    });
});

function clearNotes() {
    let data = {
        opportunity_id: opportunity
    };
    if (user_id != null) {
        data.user_id = user_id;
    }

    $.ajax({
        url: '/opportunities/notes',
        data: data,
        success: function(notes) {
            $('.add-note-area').hide();
            $('.edit-note-area').hide();
            $("#note-to-add").val('');
            $("#note-to-edit").val('');
            $('.notes-area').empty();
            $('.notes-area').show();
            $("#add-note").addClass('d-inline-block').removeClass('d-none');
            
            if(notes.notes.length > 0)
            {
                $(notes.notes).each(function(key, note){
                    date = new Date(note.created_at)
                    $('.notes-area').addClass('notes__container').append('<div class="notes__wrapper py-4"><div class="row"><div class="col-auto"><p class="notes__date mb-1 lt-light-gray">' + date.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric'}) + '</p></div><div class="col-auto ml-auto"><a href="javascript:void(0)" class="edit-note notes__action lt-light-gray" data-note-id="' + note.id + '"><i class="fe fe-edit-2"></i> Edit</a><a href="javascript:void(0)" class="delete-note notes__action lt-light-gray" data-note-id="' + note.id + '"><i class="fe fe-x"></i>Delete</a></div></div><div class="row"><div class="col-12">' + note.note  + '</div></div></div>');
                });
            }
            else {
                $('.notes-area').removeClass('notes__container').append('<div class="row mt-3"><div class="col-md-12"><p class="m-0 p-0">No notes for this opportunity. Add a new note by clicking the "Add A Note" button above.</p></div></div>')
            }
        }
    });
}
