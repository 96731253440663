$(function() {
  var body = $('body');


  // if auth was used bring it back
  if(getStorage('auth_sent') == true)
  {
    $('[data-user-id]').data('user-id', localStorage.getItem('login_user'));
  }

  function setStorage(keyName, keyValue) {
    const data = {
      value: keyValue,                  // store the value within this object
      ttl: Date.now() + 10 * 60000 // store the TTL (time to live)
    }

    // store data in LocalStorage
    localStorage.setItem(keyName, JSON.stringify(data));
  };

  function getStorage(keyName) {
    const data = localStorage.getItem(keyName);
    if (!data) {     // if no value exists associated with the key, return null
        return null;
    }

    const item = JSON.parse(data);

    // If TTL has expired, remove the item from localStorage and return null
    if (Date.now() > item.ttl) {
        localStorage.removeItem(keyName);
        return null;
    }

    // return data if not expired
    return item.value;
  };

  function loaderSvg() {
    return '<svg width="22" height="22" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r"begin="0s" dur="1.8s"values="1; 20"calcMode="spline"keyTimes="0; 1"keySplines="0.165, 0.84, 0.44, 1"repeatCount="indefinite" /><animate attributeName="stroke-opacity"begin="0s" dur="1.8s"values="1; 0"calcMode="spline"keyTimes="0; 1"keySplines="0.3, 0.61, 0.355, 1"repeatCount="indefinite" /></circle><circle cx="22" cy="22" r="1"><animate attributeName="r"begin="-0.9s" dur="1.8s"values="1; 20"calcMode="spline"keyTimes="0; 1"keySplines="0.165, 0.84, 0.44, 1"repeatCount="indefinite" /><animate attributeName="stroke-opacity"begin="-0.9s" dur="1.8s"values="1; 0"calcMode="spline"keyTimes="0; 1"keySplines="0.3, 0.61, 0.355, 1"repeatCount="indefinite" /></circle></g></svg>';
  }
 
  function validEmail(email) {
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  function validPhone(phone) {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return regex.test(phone);
  }
  //masking phone
  $('[data_user_phone]').on('input', function(e) {
      var x = $(this).val().replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      $(this).val( !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));
    });
 
  function modalLogin(e) {

    var $this = $(e.target),
    html = $this.html(),
    token = $('[data-full-verification-code]').val().replace(/-/g, ""),
    user_id = $('[data-user-id]').data('user-id'),
    btn_id = $this.attr('id'),
    response_location = null;

    $this.html(loaderSvg()).addClass('btn--loading');

    $.ajax({
        url: '/sign_in_with_modal',
        method: 'POST',
        data: {
          user_id: user_id,
          login_token: token,
          response_location: localStorage.getItem('response_location')
        },
        success: function(login_data) {
            // clear the data store
					  localStorage.removeItem('auth_sent')
					  localStorage.removeItem('response_location')

            $('#signup-modal').modal('hide');
            $('#login-modal').modal('hide');
            $('#login-code-modal').modal('hide');
            console.log(login_data.response_location);

            if ( localStorage.getItem('modal') === 'signup') {
                
              $('#user-form-modal').modal('show');

              dataLayer.push({
                'event': 'funnel',
                'funnel_value': 3
              });

            
            } else {

              window.location = login_data.response_location;

            }
        }
      });
  }

  function validateForm($form) {

    var form_data_arr = $form.serializeArray();
    var form_data_arr_formatted = [];
    var valid = true;

    $('.validation').css('display', 'none');

    for (i = 0; i < form_data_arr.length; i++ ) {

      form_data_arr_formatted[form_data_arr[i]['name']] = form_data_arr[i]['value'];

      if( !form_data_arr[i]['value'] ||
        form_data_arr[i]['value'] == '' ||
        (form_data_arr[i]['name'].indexOf('phone') > -1 && !validPhone(form_data_arr[i]['value'])) ||
        (form_data_arr[i]['name'].indexOf('email') > -1 && !validEmail(form_data_arr[i]['value']))
      ) {

        $('[name="' + form_data_arr[i]['name'] + '"]').siblings('.validation').css('display', 'block');
        valid = false;
      }
    }

    return !valid ? valid : form_data_arr_formatted;
  }


  function switchStep(e) {
    e.preventDefault();

    var $this = $(e.target),
    html = $this.html(),
    go_to_step = $this.data('switch-step');


    if ( localStorage.getItem('modal') === 'signup' && go_to_step == 1) {

      $('#signup-modal').modal('show');
      $('#login-modal').modal('hide');
      $('#login-code-modal').modal('hide');

      return;
    }
    
    if ( localStorage.getItem('modal') === 'login' && go_to_step == 1) {

      $('#signup-modal').modal('hide');
      $('#login-modal').modal('show');
      $('#login-code-modal').modal('hide');

      return;
    }

    if( go_to_step === 2 ) { //verify code

      var $form = $this.parents('form');
      var form_data_arr = validateForm($form);

      if( form_data_arr ) {

        if (go_to_step > 1 ) {
          $this.html(loaderSvg()).removeAttr('data-switch-step').addClass('btn--loading');
        }
 
       // if ( localStorage.getItem('modal') === 'signup') {
          // if they are signing up create a user
          ajax_url = '/users/validate-new-email';

      /* } else {

          // if they are not signing up just find user
          ajax_url = '/users/validate-email';

        }
      */

        var data = {
          email: form_data_arr['user[email]']
        };

        if($(this).data('impersonate') != undefined) {
          data.admin = true;
        }

        email = data['email']

      $.ajax({
        url: ajax_url,
        data: data,
        success: function(user) {

          if( user.new_user === true) {

            localStorage.setItem('modal', 'signup');
            
            dataLayer.push({
              'event': 'funnel',
              'funnel_value': 2
            });

          } else {

            localStorage.setItem('modal', 'login');

          } 

          $('#signup-modal').modal('hide');
          $('#login-modal').modal('hide');
          $('#members-only-modal').modal('hide');
          
          setTimeout(function() {
            $('#login-code-modal').modal('show');
          }, 500)

          if (go_to_step > 1 ) {
            $this.html(html).attr('data-switch-step', go_to_step).removeClass('btn--loading');
          }
          
          if ($this.data('no-modal')) {
            $('[data-step]').hide()
            $('[data-step=' + $this.data('switch-step') + ']').show();
          }


          if(user.error != undefined)
          {
            $('[data-step="3"]').show();
          }
          else
          {
            // set local stroage to keep auth
            setStorage('auth_sent', true);
            localStorage.setItem('login_user', user.user_id);
            $('[data-user-id]').data('user-id', user.user_id);
            
            dataLayer.push({'event': 'lt_signup_submit'});


          }
          // some response if no user exists and they're logging in
          var email_confirmation = '';

          if (user.email) {
            email_confirmation += user.email;
          }

          if (user.email && user.phone) {
            email_confirmation += ' and ';
          }


          if (user.phone) {
              email_confirmation += '(XXX) XXX-' + user.phone;
          }

          $('[data-email-confirmation]').html('Code sent to: ' + email_confirmation);

        }
      });
     }
    } 
  }

  function updateUser(e) {
    e.preventDefault();

    var $this = $(e.target);
    var $form = $this.parents('form');
    var form_data_arr = validateForm($form);

      if( form_data_arr ) {

        var data = {
          user_id: $('[data-user-id]').data('user-id'),
          first_name: form_data_arr['user[first_name]'],
          last_name: form_data_arr['user[last_name]'],
          business_name: form_data_arr['user[business_name]'],
          mobile_phone: form_data_arr['user[phone]'],
          authentication_preference: form_data_arr['user[authentication_preference]'],
          response_location: localStorage.getItem('response_location')
        };
      }

      if(window.location.href.indexOf('?invite=') != -1 ) {
        data.full_user = true;
        data.response_location = '/opportunities'
      }

      $.ajax({
        url: '/users/update-user',
        method: 'POST',
        data: data,
        success: function(response) {

          dataLayer.push({
            'event': 'funnel',
            'funnel_value': 4
          });

          localStorage.removeItem('response_location');

          window.location = response.response_location;

        }
      });

  }

  function setReferral(e) {
    
    var $this = $(e.target);

    // if( $this.data('target') == ('#login-modal' || '#signup-modal') && getStorage('auth_sent') == true) {

    //   $('#signup-modal').modal('hide');
    //   $('#login-modal').modal('hide');
    //   $('#members-only-modal').modal('hide');
    //   $('#login-code-modal').modal('show');

    // }

    if($this.data('path')) {

      localStorage.setItem('response_location', $this.data('path'));
    
    } else {

      localStorage.removeItem('response_location');

    }
  }

  function showOptOut(e) {

    e.preventDefault();

    var $this = $(e.target);

    jQuery('[data-optout-link]').show();
    jQuery('[data-optout-link] a').attr('href', $this.data('path'));

  }

  function modalType(e) {
    const type = $(e.target).data('target');

    if(type === '#login-modal') {

      localStorage.setItem('modal', 'login');

    } else {

      localStorage.setItem('modal', 'signup');

    }
  }

  body.on('click', '#login-button', modalLogin);
  body.on('click', '#update-user-button', updateUser);

  body.on('click', '[data-switch-step]', switchStep);
  body.on('click', '[data-toggle]', setReferral);
  body.on('click', '[data-optout]', showOptOut);
  
  $('[data-target]').click(modalType);

  $('#login-code-modal').on('shown.bs.modal', function() {
    $('#one-time-code').focus();
  });

  $('[data-funnel]').click(function() {
    dataLayer.push({
      'event': 'funnel',
      'funnel_value': 1
    });
  });


  $('#one-time-code').on('keyup', function(e) {   
    var value = $("#one-time-code").val();

    if (e.key.match(/[0-9]/) == null) {
      value = value.replace(e.key, "");
      $(this).val(value);
      return;
    }

    if (value.length == 3) {
        $(this).val(value + "-")
    }

  });
 


});
