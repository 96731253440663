// import Swiper JS
import Swiper, { Autoplay, Navigation} from 'swiper';

Swiper.use([Autoplay, Navigation]);


function loaderSvg() {
  return '<svg width="22" height="22" viewBox="0 0 44 44" xmlns="http://www.w3.org/2000/svg" stroke="#fff"><g fill="none" fill-rule="evenodd" stroke-width="2"><circle cx="22" cy="22" r="1"><animate attributeName="r"begin="0s" dur="1.8s"values="1; 20"calcMode="spline"keyTimes="0; 1"keySplines="0.165, 0.84, 0.44, 1"repeatCount="indefinite" /><animate attributeName="stroke-opacity"begin="0s" dur="1.8s"values="1; 0"calcMode="spline"keyTimes="0; 1"keySplines="0.3, 0.61, 0.355, 1"repeatCount="indefinite" /></circle><circle cx="22" cy="22" r="1"><animate attributeName="r"begin="-0.9s" dur="1.8s"values="1; 20"calcMode="spline"keyTimes="0; 1"keySplines="0.165, 0.84, 0.44, 1"repeatCount="indefinite" /><animate attributeName="stroke-opacity"begin="-0.9s" dur="1.8s"values="1; 0"calcMode="spline"keyTimes="0; 1"keySplines="0.3, 0.61, 0.355, 1"repeatCount="indefinite" /></circle></g></svg>';
}

$(document).on('click', '.btn-next-step-opportunity', function(event) {

	event.preventDefault();
	const opportunityID = $(this).data('opportunity-id');
	const step = $(this).data('step');
	const save = $(this).data('save');
	const lastStep = $('#opportunity_last_step').val();

  if (step === 'next' && !save && !validSelection(lastStep)) return;
	//if (  !validSelection(lastStep)) return;
  //console.log('test')

  clearPopovers();

	const data = getDataForOpportunity();

	$.ajax({
		type: "PUT",
		url: "/searches/" + opportunityID,
		data: {
			step,
			save,
			...data,
		},
		dataType: 'script'
	});

});

function clearPopovers() {
  $('#opportunity_approve_fee_discussion_false').popover('hide');
  $('#opportunity_approve_sharing_false').popover('hide');
};

function getDataForOpportunity() {
	const data = {}
	$('#edit-opportunity-form :input').each( function() {

		const checked = $(this).prop('checked');
		const type = $(this).attr('type');
		const input_name = $(this).attr('name');
		const input_value = $(this).val();
		const text_area = $(this).is("textarea");

		if ( input_name && !['_method', 'authenticity_token'].includes(input_name) ) {
			if (type == 'radio' && checked ) {
				data[input_name] = input_value;
			}
			if (type == 'checkbox' && checked ) {
				if ( data[input_name] ) {
					data[input_name] = [...data[input_name], input_value]
				} else {
					data[input_name] = [input_value];
				}
			}
			if (['text', 'number', 'date', 'hidden'].includes(type)) {
				data[input_name] = input_value;
			}
			if (text_area) {
				data[input_name] = input_value;
			}
		}
	});
	return data;

}

$(document).on('click', 'input[type=radio]', function() {
  const checked = $(this).prop('checked');
  const otherSelected = $(this).hasClass('other-loan-type-input');
  const target = $('#other-loan-type-input');
  if (otherSelected && checked) {
    target.removeAttr('hidden');
  } else {
  	$('#opportunity_other_loan_type').val('');
    target.attr('hidden', 'hidden');
  }
})

function validSelection(lastStep) {
  let isValid = true;
  let message = '';
  switch (lastStep) {
  	case 'introduction':
  		break;
    case 'categories':
      isValid = $("input:radio:checked.loan-category").length > 0;
      break;
    case 'loan_type':
      isValid = $("input:radio:checked.loan-type").length > 0;
      break;
    case 'relationship':
      isValid = $("input:checkbox:checked.relationship").length > 0;
      break;
    case 'trade_type':
      isValid = $("input:checkbox:checked.trade-type").length > 0;
      break;
    case 'operational_servicing':
      isValid = $('input:checkbox:checked.operational-servicing').length > 0;
      break;
    case 'legal_servicing':
      isValid = $('input:checkbox:checked.legal-servicing').length > 0;
      break;
    case 'commitment':
      isValid = $('input:checkbox:checked.commitment').length > 0;
      break;
    case 'portfolio':
      isValid = $('input:checkbox:checked.portfolio').length > 0;
      break;

    case 'trade_size_flow':
      isValid = $('#opportunity_minimum_flow_trade').val().length > 0 || $('#no_minimum_flow_trade').prop('checked');
      message = 'Please enter a minimum value or select No Minimum.'
    	if (!isValid) break;
      isValid = $('#opportunity_maximum_flow_trade').val().length > 0 || $('#no_maximum_flow_trade').prop('checked');
      message = 'Please enter a maximim value or select No Maximum.'
      break

    case 'trade_size_relationship':
      isValid = $('#opportunity_minimum_relationship_trade').val().length > 0 || $('#no_minimum_relationship_trade').prop('checked');
      message = 'Please enter a minimum value or select No Minimum.'
      if (!isValid) break;
      isValid = $('#opportunity_maximum_relationship_trade').val().length > 0 || $('#no_maximum_relationship_trade').prop('checked');
      message = 'Please enter a maximim value or select No Maximum.'
      break

    case 'guideline_exlusion':
    	break;
    case 'included_us_states':
      isValid = $('input:checkbox:checked.included-us-states').length > 0;
      break;
    case 'state_exclusion':
    	break;
    case 'required_us_states':
    	// should be okay to not require any states
      //isValid = $('input:checkbox:checked.required-us-states').length > 0;
      break;
    case 'performance':
      isValid = $('input:checkbox:checked.performance').length > 0;
      break;
    case 'performance_exclusion':
    	break;
    case 'required_performance':
    	// could not require any performance buckets
    	 //isValid = $('input:checkbox:checked.required-performance').length > 0;
    	break;
    case 'seller_company_type':
      isValid = $('input:radio:checked.seller-company-type').length > 0;
      break;
    case 'company_type':
      isValid = $('input:checkbox:checked.company-type').length > 0;
      break;
    case 'tags':
      break;
    case 'provide_statistics':
      break;
    case 'statistics':
      break;
    case 'offering_type':
    	isValid = $('input:radio:checked.offering-type').length > 0;
      break;
    case 'bids_due':
    	break;
    case 'notes':
    	break;
    case 'approve_sharing':
    	break;
    case 'approve_fee_discussion':
    	break;
  };
  if (!isValid) {
    if (message.length === 0) {
      message = 'Please select at least one option before clicking Next.'
    }
    $('#validation-error-message').html(message);
  } else {
    $('#validation-error-message').html('');
  }
  return isValid;
}



function update_opportunity_status(id, checked) {

  const opportunity_status = checked ? 'active' : 'inactive';
  if (checked){
    $('html').removeClass('search-profile-inactive').addClass('search-profile-active');
    $('[data-active-toggle-label]').html('Active');
  } else {
    $('html').removeClass('search-profile-active').addClass('search-profile-inactive');
    $('[data-active-toggle-label]').html('Inactive');
  }

  $.ajax({
    type: "PUT",
    url: "/searches/" + id + "/update_status",
    data: { opportunity_status },
    dataType: 'script'
  });
};

function pop_activate_disabled_message() {
  $('#search-activate-button').popover({
    trigger: 'focus',
    html: true,
    content: "<span class='text-danger'>You must be a full access member to activate a search.  Please contact LendTrade to upgrade your account.</span>",
    placement: 'top',
  })
};


function update_opportunity_sharing(id, checked) {
  const approve_sharing = checked;
	const person_type = $('#what-type-will-see span').data('type')

	if (checked) {
    $('html').removeClass('search-profile-private').addClass('search-profile-public');
    $('[data-public-toggle-label]').html('Public');
		$('#what-type-will-see span').text(`What ${person_type} Will See`);
  } else {
    $('html').removeClass('search-profile-public').addClass('search-profile-private');
    $('[data-public-toggle-label]').html('Private');
		$('#what-type-will-see span').text(`What ${person_type} Could See`)
  }

  $.ajax({
    type: "PUT",
    url: "/searches/" + id + "/approve_sharing",
    data: { approve_sharing },
    dataType: 'script'
  });
};



$(document).on('click', '.opportunity-permission-checkbox', function(e) {

  const id = $(this).data('opportunity-id');
  const column_name = $(this).data('column-name');
  const permission = $(this).prop('checked') ? 'is_public' : 'is_private';

  $.ajax({
    type: "PUT",
    url: "/opportunity_permissions/" + id,
    data: { column_name, permission },
    dataType: 'script'
  });
});

$(document).on('change', '.trade-size-button', function() {
  const checked = $(this).prop('checked');
  const target_id = $(this).data('target');
  const target = $(target_id);
  const hidden_id = $(this).data('hidden');
  const hidden = $(hidden_id);
  if (checked) {
    target.val('').attr("disabled", true);
    hidden.val('true')
  } else {
    target.attr("disabled", false);
    hidden.val('false')
  }
});

$(document).ready(function() {

  $(document).on('change', '[data-active-toggle]', function(event) {
    const id = $(this).data('opportunity-id');
    const checked = $(this).is(':checked');
    update_opportunity_status(id, checked);
  });

  $(document).on('change', '[data-public-toggle]', function(event) {
    const id = $(this).data('opportunity-id');
    const checked = $(this).is(':checked');
    update_opportunity_sharing(id, checked);
  });

  noticeCheck();

  $('body').on('click', '[data_notice_dismiss]', dismissNotice);
  $('[data-toggle="tooltip"]').tooltip()

 
  var mySwiper = new Swiper('.swiper-home', {
    loop: true,
    grabCursor: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      768: {
        slidesPerView: 3,
      }
    }
  }); 


  $('[data-toggle-filters]').click(function(e) {
    e.preventDefault();

    var $icon = $(this).find('i')

    $('[data-filters]').toggleClass('d-none');
    $('.dataTables_filter').toggleClass('d-block');
    $('.datatable--table').toggleClass('open');

    $icon.toggleClass('fe-x-circle');
    $icon.toggleClass('fe-arrow-down');

  });


  //lazy-loading homepage animation
  var anim = document.querySelectorAll('.animation--bg');
  var header = document.querySelectorAll('.section--home .section__title');
  var btn = document.querySelectorAll('.btn--animate');

  var config = {
    // If image gets within 50px go get it
    rootMargin: '50px 0px',
    threshold: 0.01
  };

  var observer = new IntersectionObserver(onIntersection, config);

  anim.forEach(anim => {
    observer.observe(anim);
  });

  header.forEach(anim => {
    observer.observe(anim);
  });

  btn.forEach(anim => {
    observer.observe(anim);
  });

  function onIntersection(entries) {

    entries.forEach(entry => {

      if (entry.intersectionRatio > 0) {
        $(entry.target).addClass('in-view');
      } else {
        $(entry.target).removeClass('in-view');
      }
    });
  };




});

function intererestedChange(e) {
  e.preventDefault();
  var $selected_option = $(e.target);
  var interested_value = null;

  $selected_option.toggleClass('select--active');

  if( $selected_option.hasClass('select--active')) {

    interested_value = $selected_option.data('interested');

  }
  var opp_id = $selected_option.data('opportunity');

  $('[data-opportunity="'+ opp_id +'"]').not($selected_option).removeClass('select--active');

  recordInterest(opp_id, interested_value, null); 
};

function dismissNotice(e) {
  $('.notice--cookie').hide();
  localStorage.setItem('cookie_notice', true);
}

function noticeCheck() {
  if (localStorage.getItem("cookie_notice") === null) {
    $('.notice--cookie').show();
  }
}


function intererestedSelectChange(e) {
  e.preventDefault();

  var $this = $(e.target);
  var opp_id = $this.data('opportunity-id');
  var interested_value = $this.val(); 
  var user_id = $this.data('user')

  recordInterest(opp_id, interested_value, user_id); 
};

function discussChange(e) {
  e.preventDefault(); 
  const $this = $(e.target); 
  const opp_id = $this.data('opportunity-id');

  sendDiscussEmail($this, opp_id);
};

function recordInterest(opp_id, interested_value, user_id) {

  let data = {};

  data.interest = interested_value;
  
  if (user_id) {
    data.user_id = user_id;
  }

  $.ajax({
    url: "/opportunities/" + opp_id + "/record-interest",
    type: 'PUT',
    data: data,
    success: function(interested_data) {
      //location.reload();

      const html = '<div role="alert" data-alert="" class="alert alert-success alert--active fade show">\
					Updated!\
						<button type="button" class="close" data-dismiss="alert" aria-label="Close">\
						<span aria-hidden="true">×</span>\
						</button>\
					</div>';

      jQuery('body').append(html);

      setTimeout(function() {
        jQuery('.alert--active').remove(); 
      }, 3000)

    }
  });
};

function sendDiscussEmail($this, opp_id) {
  
  $this.html(loaderSvg()).addClass('btn--loading');

  $.ajax({
    url: "/opportunities/" + opp_id + "/discuss",
    type: 'PUT',
    success: function(response){
      setTimeout(function() {
        $this.removeClass('bg-black').addClass('btn--complete');
        $this.html('<span><i class="fe fe-check"></i></span> We\'ll be in touch!').removeClass('text-white').removeClass('btn--loading').addClass('text-black').removeAttr('data-discuss');

      }, 2000);
    }
  });
}

function tableClick(e) {
  const $this = $(e.target),
    $btn = $(this).parents('.table--click').find('.table--btn'),
    target = $btn.data('target'),
    href = $btn.attr('href');

  if( $this.hasClass('table--noclick') || $this.parent('td').hasClass('table--noclick') ) {
    return;
  }  

  if(target) {
    $(target).modal();
  } else {
    window.location = href;
  }
};

function privateMatchModal() {

  const id = $(this).data('opportunity-id');
  $('#private-match-modal').find('[data-opportunity-id]').data('opportunity-id', id);
}

function detailedMatchClick() {
  const opp_id = $(this).data('match');
  $('[data-discuss]').data('opportunity-id', opp_id);

}

$(document).ready(function() {

  $('body').on('click', '[data-discuss]', discussChange);
  $('body').on('change', '[data-interested-select]', intererestedSelectChange);
  $('body').on('click', '.select--item--like', intererestedChange);
  $('body').on('click', '.table--click td', tableClick);
  $('body').on('click', '[data-target="#private-match-modal"]', privateMatchModal);

  $('[data-show-more]').click(function() {
      $(this).find('i').toggleClass('fe-plus-circle').toggleClass('fe-minus-circle');
  });

  $('body').on('click', '[data-match]', detailedMatchClick); 

});