//  if you're having problems getting webpacker to recognize an node modeule:
// docker-compose down --remove-orphans

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("select2");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


import Inputmask from "inputmask";

// import Swiper styles
import 'swiper/swiper-bundle.css';
import "./bootstrap_custom.js";

import "../stylesheets/application";
import "./search";
import "./signin";
import "./opportunity";
import "./user_filter";

window.jQuery = $;
window.$ = $;

document.addEventListener('touchstart', function addtouchclass() {
  document.documentElement.classList.add('is-touch')
  document.removeEventListener('touchstart', addtouchclass, false)
}, false)


$(document).ready(function() {

  $('[data-next-section]').click(function() {

    let pageHeight = window.innerHeight;

    $("html, body").animate({ scrollTop: pageHeight - 105 }, 375);


  });
});

// match notifications

if (user_logged_in ) {
$.ajax({
  url: '/match-notifications',
  success: function(data) {
    // if there is a notification display it
    if(data.notification != null)
    {
      if(data.public == true) {
        $("#match-notification-link").append('<a class="btn btn-primary px-5 match-compatibility-report" href="javascript:void(0)">View Compatibility Report</a>');
      } else {
        $("#match-notification-link").append('<a href="#" data-discuss="true" data-opportunity-id="' + data.notification.opportunity_id + '" data-user="' + data.notification.user_id + '" class="btn btn--bold text-white d-md-block bg-orange mr-3"><i class="fe fe-message-circle "></i> Yes, I\'m Interested</a><a href="#" class="btn btn--bold text-black d-md-block bg-xlight-gray" data-dismiss="modal" aria-label="Close">Close</a>');
        $('[data-match-content]').html("You have a match! The owner of the opportunity you've matched with has chosen to keep their information private. Contact LendTrade to learn more about the opportunity.")
      }
      $("#match-notification-modal").modal('show');
      $("#match-notification-modal").data('notification-id', data.notification.id);
    }
  }
})
}

$(function(){
    $('#match-notification-modal').on('hidden.bs.modal', function (e) {
      // mark the notification as dismissed
      $.ajax({
        url: '/dismiss-match-notification',
        data: { match_id: $("#match-notification-modal").data('notification-id') }
      })
    });

    $(document).on('click', '.match-compatibility-report', function() {
      // go to the compatibility report and mark the notification as dismissed
      $.ajax({
        url: '/viewed-match-notification-compatibility',
        data: { match_id: $("#match-notification-modal").data('notification-id') },
        success: function(data) {
          window.location = data.url;
        }
      })
    });

    $(document).on('click', '.match-compatibility-pdf', function() {
      // go to the compatibility report and mark the notification as dismissed
      $.ajax({
        url: '/viewed-match-notification-compatibility',
        data: { match_id: $("#match-notification-modal").data('notification-id') },
        success: function(match_url) {
          window.location = match_url.pdf;
        }
      })
    });
});

setTimeout( function() {
  $('.alert').fadeOut('slow');
}, 5000);
