$(document).on('click', '.loan-type-checkbox', function() {
	const selected = $(this).prop('checked');
	const opportunity_type = $(this).data('opportunity-type');
	const id = $(this).val();

	if (selected) {
		$(`#${opportunity_type}_loan_type_view_${id}`).addClass('lt-blue');
		$(`#${opportunity_type}_loan_type_link_${id}`).removeClass('text-muted');
	} else {
		$(`#${opportunity_type}_loan_type_link_${id}`).addClass('text-muted');
		$(`#${opportunity_type}_loan_type_view_${id}`).removeClass('lt-blue');
	}

	$.ajax({
    type: "PUT",
    url: "/user_filters/" + id + "/select_loan_type",
    data: { opportunity_type, selected },
    dataType: 'script'
  });
});

$(document).on('click', '.email-checkbox', function(){
	const selected = $(this).prop('checked');
	const user_id = $(this).val();
	const email_type = $(this).data('email-type')

	$.ajax({
		type: 'PUT',
		url: '/user_filters/' + user_id + '/email_preferences',
		data: { selected, email_type },
		dataType: 'script'
	});

});
